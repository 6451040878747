import asyncAppend from "@elements/async-append";
import {find, findAll, findAllIn, findIn, on, trigger} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import Modal from 'bootstrap/js/dist/modal';
import axios from "axios";
export function init() {
    let modal = find('.js-ajax-modal__modal');
    onFind('.js-ajax-modal__btn', (btn)=> {
        let url = btn.getAttribute('data-content-url');
        on('click', () =>{
            let pendingRequest = axios({
                method: 'POST',
                url: url,
                headers: {'X-Requested-With': 'XMLHttpRequest'}
            });
            asyncAppend({
                    target: findAllIn('.js-ajax-modal__result', modal),
                    loading: findAllIn('.js-ajax-modal__loading', modal)
                },  pendingRequest.then((x) => {
                    return x.data;
                })
            );
        }, btn);
    });
}